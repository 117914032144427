import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_ENV = process.env.APP_ENV;

Sentry.init({
	dsn: SENTRY_DSN,
	environment: APP_ENV,
	tracesSampleRate: 0.5,
	autoSessionTracking: false
});